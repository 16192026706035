import React, { useRef, useEffect, useState } from "react";
import { center, right, left } from "./ContactForm.module.css";

const ContactForm = () => {
  const [posRight, setPosRight] = useState(right);
  const [posLeft, setPosLeft] = useState(left);
  const observeEl = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "10px",
      threshold: 0.2,
    };
    let observer = new IntersectionObserver(
      (IntersectionObserverEntry, observe) =>
        triggerAnimation(IntersectionObserverEntry, observe),
      options
    );
    observer.observe(observeEl.current);
  }, [observeEl]);

  const triggerAnimation = (IntersectionObserverEntry, observer) => {
    let ratio = IntersectionObserverEntry[0].intersectionRatio;
    if (ratio > 0.2) {
      observer.unobserve(observeEl.current);
      setPosRight(center);
      setPosLeft(center);
    }
  };

  return (
    <form
      method="post"
      data-netlify="true"
      name="contact"
      ref={observeEl}
      className="bg-white flex flex-col items-center space-y-10"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <Input className={posRight} label="Name" name="name" />
      <Input className={posLeft} label="Vorname" name="vorname" />
      <Input className={posRight} label="Email" name="email" type="email" />
      <Textarea className={posLeft} label="Nachricht" name="nachricht" />
      <button
        className={`${posRight} p-3 bg-primary text-black rounded hover:bg-primary_bright duration-500 hover:text-black`}
        text="Send"
        type="submit"
      >
        Abschicken
      </button>
    </form>
  );
};

const Input = ({ label, name, type = "text", className = "" }) => {
  return (
    <div className={`flex flex-col text-center w-64 md:w-96 ${className}`}>
      <label className="mb-1 text-black font-bold" htmlFor={name}>
        {label}
      </label>

      <input
        className="p-2 text-black focus:outline-none bg-white border border-black"
        type={type}
        id={name}
        name={name}
      />
    </div>
  );
};

const Textarea = ({ label, name, rows = 10, className = "" }) => {
  return (
    <div className={`flex flex-col text-center w-64 md:w-96 ${className}`}>
      <label className="mb-1 text-black font-bold" htmlFor={name}>
        {label}
      </label>

      <textarea
        className="p-2 text-black focus:outline-none bg-white border border-black"
        rows={rows}
        id={name}
        name={name}
      />
    </div>
  );
};

export default ContactForm;
