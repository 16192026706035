import React from "react";

const Title = ({ title }) => {
  return (
    <div className="text-center my-10">
      <p className="text-3xl">{title}</p>
      <div className="w-3/4  bg-gradient-to-r from-white via-primary to-white h-2 mx-auto max-w-screen-sm my-3"/>
    </div>
  );
};

export default Title;
