import React from "react";
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import { StaticImage } from "gatsby-plugin-image";
import Title from "../components/title";

const ContactPage = () => {
  return (
    <Layout pageTitle="Kontakt">
      <StaticImage
        className="w-full"
        src="../images/contact2.jpg"
        alt="contact"
      />
      <Title title="Kontakt" />
      <p className="text-center">
        Sehr einfach können Sie mich über das untenstehende Kontaktformular
        erreichen:
      </p>
      <ContactForm />
      <Title title="Adresse" />
      <div className="my-5">
        <p className="text-center">Daniela Gisler</p>
        <p className="text-center">Moosbergstrasse 60</p>
        <p className="text-center">6284 Gelfingen</p>
        <p className="text-center">Tel: 079 465 89 13</p>
        <a
          className="text-center block"
          href="mailto:gisler.daniela@bluewin.ch"
        >
          Email: gisler.daniela@bluewin.ch
        </a>
      </div>
    </Layout>
  );
};

export default ContactPage;
